import { NavLink, useHistory } from 'react-router-dom';
import { ContainerMenu } from './styled';
import { useConfirm } from 'hooks/useConfirm';

export interface IMenuItem {
  id: number;
  label: string;
  url: string;
}

export interface IMenu {
  menuList: IMenuItem[];
  confirmOnClick?: boolean;
}

export const Menu = ({ menuList, confirmOnClick }: IMenu) => {
  const history = useHistory();
  const { confirm } = useConfirm();

  const handleClick = (url: string) => {
    if (confirmOnClick) {
      void confirm()
        .then(() => {
          history.push(url);
        })
        .catch((_) => {});
    } else {
      history.push(url);
    }
  };

  return (
    <ContainerMenu>
      {menuList.map((item) => (
        <NavLink
          key={`item-menu-${item.id}`}
          to={item.url}
          onClick={(e) => {
            e.preventDefault();
            handleClick(item.url);
          }}
          data-testid={`item-menu-${item.id}`}
          activeClassName="button-menu-active"
          isActive={(_, location) => {
            return location.pathname.includes(item.url);
          }}
        >
          {item.label}
        </NavLink>
      ))}
    </ContainerMenu>
  );
};
