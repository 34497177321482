/* eslint-disable eqeqeq */
import { yupResolver } from '@hookform/resolvers/yup';
import { validateLink } from 'helpers/functions';
import { isEmpty } from 'lodash';
import { IListsModel, IPopupButtonModel } from 'models';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { PostPopupButton } from 'requests/PopupManagement';
import * as yup from 'yup';

import { useLoading } from './loading';
import { useNotification } from './notification';
import { useConfirm } from './useConfirm';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const validateButton = (button: any) =>
  button?.text &&
  button.idColor &&
  (button.action === 4 ||
    (button.action === 1 && (validateLink(button.link) || button.internalLink)) ||
    (button.action === 2 && (button.actionFile || validateLink(button.actionLinkFile))));

const Schema = yup
  .object({
    // popupButtonName: yup.mixed().when('activeStep', {
    //   is: (activeStep: number) => activeStep === 1,
    //   then: yup.string().required('Informe o nome'),
    // }),
    popupButtonIcon: yup.mixed().when('activeStep', {
      is: (activeStep: number) => activeStep === 1,
      then: yup.string().required('Informe o icon'),
    }),
    popupButtonTitle: yup.mixed().when('activeStep', {
      is: (activeStep: number) => activeStep === 1,
      then: yup.string().required('Informe o título'),
    }),
    popupButtonSubTitle: yup.mixed().when('activeStep', {
      is: (activeStep: number) => activeStep === 1,
      then: yup.string().required('Informe o subtitle'),
    }),
    startDate: yup.mixed().when(['activeStep', 'defineDate'], {
      is: (activeStep: number, defineDate: number) => activeStep === 4 && defineDate === 1,
      then: yup.mixed().required('Informe a data de início'),
    }),
    endDate: yup.mixed().when(['activeStep', 'defineDate'], {
      is: (activeStep: number, defineDate: number) => activeStep === 4 && defineDate === 1,
      then: yup.mixed().required('Informe a data de Final'),
    }),
    csvFile: yup.mixed().when(['activeStep', 'whoCanSee'], {
      is: (activeStep: number, whoCanSee: number) => activeStep === 5 && whoCanSee === 2,
      then: yup.mixed().required('Informe o arquivo'),
    }),
    whichBranches: yup.mixed().when(['activeStep'], {
      is: (activeStep: number) => activeStep === 5,
      then: yup.array().min(1).required('Informe a filial'),
    }),
    whichClassifications: yup.mixed().when(['activeStep'], {
      is: (activeStep: number) => activeStep === 5,
      then: yup.array().min(1).required('Informe a classificação'),
    }),
    segments: yup.mixed().when(['activeStep'], {
      is: (activeStep: number) => activeStep === 5,
      then: yup.array().min(1).required('Informe o banner'),
    }),
    planPoints: yup.mixed().when(['activeStep', 'planCondition'], {
      is: (activeStep: number, planCondition: number) => activeStep === 5 && planCondition,
      then: yup.string().required('Informe a pontuação'),
    }),
    popupButtons: yup.mixed(),
    popupButtonCount: yup.mixed(),
    activeStep: yup.mixed(),
  })
  .test((value) => {
    if (value.activeStep !== 2) return true;
    const buttonA = value.popupButtons[0];
    const buttonB = value.popupButtons[1];

    const buttonAValidated = validateButton(buttonA);
    const buttonBValidated = validateButton(buttonB);

    if (value.popupButtonCount === 1) return buttonAValidated;
    if (value.popupButtonCount === 2) return buttonAValidated && buttonBValidated;
    return true;
  });

export interface IPopupButtonFormData extends IPopupButtonModel {
  activeStep: number;
}

interface IPopupButtonContext {
  isEditing: boolean;
  created: boolean;
  setCreated: (value: boolean) => void;
  loading: boolean;
  stepIsValid: (step: number) => boolean;
  activeStep: number;
  setActiveStep: (step: number) => void;
  steps: number[];
  setSteps: (steps: number[]) => void;
  handleNext: () => Promise<void>;
  setLoading: (value: boolean) => void;
  handleCloseConfirm: (url: string) => void;
  createFormPopupButtonStepper: ReturnType<typeof useForm<IPopupButtonFormData>>;
  setIsEditing: (value: boolean) => void;
  list: IListsModel;
  setList: (list: IListsModel) => void;
}

const PopupButtonContext = createContext({} as IPopupButtonContext);

export const PopupButtonProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const notify = useNotification();

  const createFormPopupButtonStepper = useForm<IPopupButtonFormData>({
    defaultValues: {
      activeStep: 1,
      popupButtonIcon: 1,
      popups: [{ name: '', value: 0 }],
      popupButtonCount: 3,
      popupButtons: [],
      endDateUndefined: false,
      defineDate: 1,
      whoCanSee: 1,
      whichBranches: [{ value: 0, label: 'Todas' }],
      whichClassifications: [{ value: 0, label: 'Todas' }],
      segments: [{ value: 0, label: 'Todos' }],
      classificationPeriod: 1,
    },
    resolver: yupResolver(Schema),
  });

  const [isEditing, setIsEditing] = useState(false);
  const [created, setCreated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [steps, setSteps] = useState([1, 2, 3, 4, 5, 6]);
  const { handleHideLoading, handleShowLoading } = useLoading();
  const { confirm } = useConfirm();
  const history = useHistory();

  useEffect(() => {
    createFormPopupButtonStepper.setValue('activeStep', activeStep, { shouldValidate: true });
  }, [activeStep]);

  const [list, setList] = useState({} as IListsModel);

  const stepIsValid = () => {
    return (
      createFormPopupButtonStepper.formState.isValid &&
      isEmpty(createFormPopupButtonStepper.formState.errors)
    );
  };

  const handleCreateBanner = async () => {
    setLoading(true);
    handleShowLoading();
    await PostPopupButton(createFormPopupButtonStepper.getValues())
      .then((_) => {
        setCreated(true);
      })
      .catch((err) => {
        setCreated(false);
        notify.error('Houve um problema', err.message, 5000);
        throw err;
      })
      .finally(() => {
        setLoading(false);
        handleHideLoading();
      });
  };

  const handleNext = async () => {
    if (isEditing) {
      if (stepIsValid()) {
        setIsEditing(false);
        setActiveStep(6);
        return;
      }
    }

    // se já estiver no último step, redireciona para o menu
    if (activeStep < steps.length) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      return;
    }

    await handleCreateBanner();
  };

  const handleCloseConfirm = (url: string) => {
    void confirm().then(() => {
      history.push(url);
    });
  };

  return (
    <PopupButtonContext.Provider
      value={{
        isEditing,
        setIsEditing,
        created,
        setCreated,
        loading,
        stepIsValid,
        activeStep,
        setActiveStep,
        steps,
        handleNext,
        setSteps,
        setLoading,
        list,
        setList,
        handleCloseConfirm,
        createFormPopupButtonStepper,
      }}
    >
      {children}
    </PopupButtonContext.Provider>
  );
};

export const usePopupButton = () => {
  const context = useContext(PopupButtonContext);

  return context;
};
