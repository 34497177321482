import { limitLenghtString } from 'helpers/functions';
import { useHistory } from 'react-router-dom';

import { ButtonRounded } from '../ButtonRounded';
import Stack from '../Stack';
import { LinkText, Subtitle, Title } from './styled';
import { turboHomeURL } from 'routers/routes';
import { useTheme } from 'hooks/useTheme';
import useIconThemes from 'hooks/iconThemes';

export interface IStepCreated {
  title: string;
  name: string;
  route: string;
  children?: React.ReactNode;
  custonSubtitle?: string;
  custonLinkText?: string;
  custonLabelbuttonAccess?: string;
  justifyContent?: string;
  height?: string;
  onClickFirstButton?: () => void;
}

export const StepCreated: React.FC<IStepCreated> = ({
  title,
  name,
  route,
  children,
  custonSubtitle,
  custonLinkText,
  custonLabelbuttonAccess,
  justifyContent,
  height,
  onClickFirstButton,
}): JSX.Element => {
  const history = useHistory();

  const { currentTheme } = useTheme();
  const iconThemes = useIconThemes();

  return (
    <Stack
      direction="column"
      minHeight="250px"
      width="100%"
      justifyContent={justifyContent}
      height={height}
      data-testid="stack-step-created"
      padding="30px"
    >
      <Stack direction="column" flex="1">
        <Stack direction="row">
          <img src={iconThemes[currentTheme.theme]?.IconCheckAround} alt="img" />
          <Title>&quot;{limitLenghtString(title)}&quot; foi publicado com sucesso.</Title>
        </Stack>
        {children && (
          <Stack marginTop="4px" marginBottom="4px">
            {children}
          </Stack>
        )}
        <Subtitle>
          {custonSubtitle ?? `Você pode alterar detalhes deste e outros ${name} acessando o`}
        </Subtitle>
        <LinkText onClick={() => history.replace(route)}>
          {custonLinkText ?? `Gerenciador de ${name}.`}
        </LinkText>
      </Stack>
      <Stack direction="row" justifyContent="flex-end">
        <ButtonRounded
          color={currentTheme.colors.primary}
          label={custonLabelbuttonAccess ?? `Acessar Gerenciador de ${name}`}
          iconSrc={iconThemes[currentTheme.theme]?.IconEnterHover}
          click={onClickFirstButton ?? (() => history.replace(route))}
          marginRight="12px"
          outline
        />
        <ButtonRounded
          background={currentTheme.colors.primary}
          color={currentTheme.colors.white}
          label={'Página inicial'}
          iconSrc="/assets/icons/icon-enter-white.svg"
          click={() => history.replace(turboHomeURL)}
        />
      </Stack>
    </Stack>
  );
};
