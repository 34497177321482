import { yupResolver } from '@hookform/resolvers/yup';
import { validateLink } from 'helpers/functions';
import { isEmpty } from 'lodash';
import { IBannerModel, IListsModel } from 'models';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { PostBanner } from 'requests/BannerManagement';
import * as yup from 'yup';

import { useLoading } from './loading';
import { useNotification } from './notification';
import { useConfirm } from './useConfirm';

export const validationSchemaCreateFormStep = yup
  .object({
    name: yup.mixed().when('activeStep', {
      is: (activeStep: number) => activeStep === 1,
      then: yup.string().required('Informe o nome'),
    }),
    bannerFile: yup.mixed().when('activeStep', {
      is: (activeStep: number) => activeStep === 1,
      then: yup.mixed().required('informe o arquivo'),
    }),
    action: yup.mixed().when('activeStep', {
      is: (activeStep: number) => activeStep === 1,
      then: yup.number().required('Informe a ação'),
    }),
    localBanner: yup.mixed().when('activeStep', {
      is: (activeStep: number) => activeStep === 1,
      then: yup.number().required('Informe o local do banner'),
    }),
    startDate: yup.mixed().when(['activeStep', 'defineDate'], {
      is: (activeStep: number, defineDate: number) => activeStep === 3 && defineDate === 1,
      then: yup.mixed().required('Informe a data de início'),
    }),
    endDate: yup.mixed().when(['activeStep', 'defineDate'], {
      is: (activeStep: number, defineDate: number) => activeStep === 3 && defineDate === 1,
      then: yup.mixed().required('Informe a data de Final'),
    }),
    csvFile: yup.mixed().when(['activeStep', 'whoCanSee'], {
      is: (activeStep: number, whoCanSee: number) => activeStep === 4 && whoCanSee === 2,
      then: yup.mixed().required('Informe o arquivo'),
    }),
    whichBranches: yup.mixed().when(['activeStep'], {
      is: (activeStep: number) => activeStep === 4,
      then: yup.array().min(1).required('Informe a filial'),
    }),
    whichClassifications: yup.mixed().when(['activeStep'], {
      is: (activeStep: number) => activeStep === 4,
      then: yup.array().min(1).required('Informe a classificação'),
    }),
    segments: yup.mixed().when(['activeStep'], {
      is: (activeStep: number) => activeStep === 4,
      then: yup.array().min(1).required('Informe o banner'),
    }),
    planPoints: yup.mixed().when(['activeStep', 'planCondition'], {
      is: (activeStep: number, planCondition: number) => activeStep === 4 && planCondition,
      then: yup.string().required(''),
    }),
    link: yup.mixed(),
    internalLink: yup.mixed(),
    actionFile: yup.mixed(),
    actionLinkFile: yup.mixed(),
    activeStep: yup.mixed(),
  })
  .test(
    'Informe uma opção de ação',
    ({ link, internalLink, actionFile, actionLinkFile, activeStep, action }) =>
      activeStep === 1
        ? action === 3 ||
          validateLink(link) ||
          internalLink ||
          actionFile ||
          validateLink(actionLinkFile)
        : true
  );

interface ICreateContext {
  isEditing: boolean;
  created: boolean;
  setCreated: (val: boolean) => void;
  loading: boolean;
  list: IListsModel;
  setList: (val: IListsModel) => void;
  stepIsValid: () => boolean;
  activeStep: number;
  setActiveStep: (val: number) => void;
  steps: number[];
  setSteps: (val: number[]) => void;
  handleNext: () => Promise<void>;
  setLoading: (val: boolean) => void;
  handleCloseConfirm: (url: string) => void;
  createFormBannerStepper: ReturnType<typeof useForm<IBannerForm>>;
  setIsEditing: (val: boolean) => void;
}

export interface IBannerForm extends IBannerModel {
  activeStep: number;
}

const CreateBannerContext = createContext({} as ICreateContext);

export const CreateBannerProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const notify = useNotification();

  const createFormBannerStepper = useForm<IBannerForm>({
    defaultValues: {
      action: 3,
      banners: [{ name: '', value: 0 }],
      endDateUndefined: false,
      defineDate: 1,
      activeStep: 1,
      whoCanSee: 1,
      whichBranches: [{ value: 0, label: 'Todas' }],
      classificationPeriod: 1,
      whichClassifications: [{ value: 0, label: 'Todas' }],
      segments: [{ value: 0, label: 'Todos' }],
      localBanner: undefined,
    },
    resolver: yupResolver(validationSchemaCreateFormStep),
  });

  const [isEditing, setIsEditing] = useState(false);
  const [created, setCreated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [steps, setSteps] = useState<number[]>([]);

  const { handleHideLoading, handleShowLoading } = useLoading();
  const { confirm } = useConfirm();
  const history = useHistory();

  useEffect(() => {
    createFormBannerStepper.setValue('activeStep', activeStep, { shouldValidate: true });
  }, [activeStep]);

  const [list, setList] = useState({} as IListsModel);

  const stepIsValid = () => {
    return (
      createFormBannerStepper.formState.isValid && isEmpty(createFormBannerStepper.formState.errors)
    );
  };

  const handleCreateBanner = async () => {
    handleShowLoading();
    setLoading(true);
    setCreated(false);
    await PostBanner(createFormBannerStepper.getValues())
      .then((_) => {
        setCreated(true);
      })
      .catch((error) => {
        setCreated(false);
        notify.error('Houve um problema', error.message, 5000);
        throw error;
      })
      .finally(() => {
        setLoading(false);
        handleHideLoading();
      });
  };

  const handleNext = async () => {
    if (isEditing) {
      if (stepIsValid()) {
        setIsEditing(false);
        setActiveStep(steps.length);
        return;
      }
    }
    // se já estiver no último step, redireciona para o menu
    if (activeStep < steps.length) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      return;
    }

    await handleCreateBanner();
  };

  const handleCloseConfirm = (url: string) => {
    void confirm().then(() => {
      history.push(url);
    });
  };

  return (
    <CreateBannerContext.Provider
      value={{
        isEditing,
        setIsEditing,
        created,
        setCreated,
        list,
        setList,
        loading,
        stepIsValid,
        activeStep,
        setActiveStep,
        steps,
        handleNext,
        setSteps,
        setLoading,
        handleCloseConfirm,
        createFormBannerStepper,
      }}
    >
      {children}
    </CreateBannerContext.Provider>
  );
};

export const useCreateBanner = () => {
  const context = useContext(CreateBannerContext);

  return context;
};
