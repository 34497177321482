import CardImages from 'components/atoms/CardImage';
import Title from 'components/atoms/Title';
import { useHistory } from 'react-router-dom';

import { WrapperTypes, CardWrapper } from './styled';
import { turboPopupsButtonCreateURL, turboPopupsImageCreateURL } from 'routers/routes';

export const PopupTypes = ({ item }: { item: any }) => {
  const history = useHistory();

  const handleClick = (value: string) => {
    switch (value) {
      case 'button':
        history.push(turboPopupsButtonCreateURL);
        break;

      case 'image':
        history.push(turboPopupsImageCreateURL);
        break;
    }
  };

  return (
    <WrapperTypes>
      <p className={'subtitle'}>Vamos começar!</p>
      <Title label="Selecione o tipo de pop-up" />

      <CardWrapper>
        {item.map((data: any, index: string) => {
          return (
            <CardImages
              key={index}
              onClick={() => handleClick(data.value)}
              title={data.title}
              subtitle={data.subtitle}
              img={data.img}
              icon={data.icon}
              iconHover={data.iconHover}
              index={index}
            />
          );
        })}
      </CardWrapper>
    </WrapperTypes>
  );
};
