import { yupResolver } from '@hookform/resolvers/yup';
import { ButtonRounded, FooterTip, Stack } from 'components/atoms';
import { IMenuItem } from 'components/atoms/Menu';
import { LayoutRegister } from 'components/templates';
import { useLoading } from 'hooks/loading';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { InsertUser } from 'requests/UserManagement';
import * as yup from 'yup';
import { Description, Flex, IconSuccess, SectionInformation } from './styled';
import Title from 'components/atoms/Title';
import FormUserData from 'components/atoms/FormUserData';
import { useTheme } from 'hooks/useTheme';
import useIconThemes from 'hooks/iconThemes';

interface UserCreateProps {
  subMenu?: IMenuItem[];
  routerBack: string;
}

const validationSchemaForm = yup.object({
  name: yup
    .string()
    .required('Informe o nome')
    .min(2, 'Nome incompleto')
    .test(
      'is-valid',
      (d) => 'Nome incompleto',
      (value) => {
        if (value) {
          const split = value.split(' ');
          return split.length >= 2 && split[0].length >= 2 && split[1].length >= 2;
        }
        return false;
      }
    ),
  email: yup.string().required('Informe o e-mail').email('E-mail inválido'),
});

export const UserCreate = ({ subMenu, routerBack }: UserCreateProps) => {
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [descriptionSuccess, setDescriptionSuccess] = useState('');

  const { handleShowLoading, handleHideLoading } = useLoading();
  const history = useHistory();

  useEffect(() => {
    // define title page
    document.title = 'Criar Usuários | Turbo | Backoffice Lojas';
  }, []);

  const navigateToMenu = () => {
    // 👇️ navigate to /contacts
    history.push(routerBack);
  };

  const { currentTheme } = useTheme();
  const iconThemes = useIconThemes();

  const createUserForm = useForm({
    defaultValues: {
      name: '',
      email: '',
    },
    resolver: yupResolver(validationSchemaForm),
    mode: 'onChange',
  });

  const handleClick = async () => {
    const { name, email } = createUserForm.getValues();

    createUserForm.trigger(['name', 'email']);
    if (!createUserForm.formState.isValid) {
      return;
    }

    handleShowLoading();
    const response = await InsertUser(name, email);
    // split text when dot and return second part
    if (response.HttpStatus === 200) {
      setDescriptionSuccess(response.Notificacoes[0]?.Mensagem);
      setIsSuccessMessage(true);
      createUserForm.clearErrors('email');
    } else if (response.HttpStatus === 400) {
      setIsSuccessMessage(false);
      createUserForm.setError('email', { message: 'E-mail já cadastrado', type: 'validate' });
    } else {
      setIsSuccessMessage(false);
    }
    handleHideLoading();
  };

  function FormRegister() {
    return (
      <LayoutRegister.Container customHeight="600px">
        <LayoutRegister.Card
          description="Criando um novo usuário para o Backoffice"
          subMenu={subMenu}
          onBack={() => history.push(routerBack)}
        >
          <Stack
            direction="column"
            padding="0.4375rem 0.9375rem 0px 0.9375rem"
            flex="1"
            overflow="auto"
          >
            <Stack
              direction="column"
              padding="0.4375rem 0.9375rem 1rem 0.9375rem"
              flex="1"
              overflow="auto"
            >
              <SectionInformation>
                <Title label="Novo usuário: Informações básicas" />
              </SectionInformation>

              <FormUserData form={createUserForm} emailEditable={true} />
            </Stack>
          </Stack>
          <FooterTip
            tip={
              ' O usuário só poderá acessar o sistema após confirmar o convite no e-mail inserido acima.'
            }
            disabledAdvance={!createUserForm.formState.isValid}
            advance={() => handleClick()}
            cancel={() => navigateToMenu()}
            buttonFinalize
          />
        </LayoutRegister.Card>
      </LayoutRegister.Container>
    );
  }

  function SuccessMessage() {
    return (
      <LayoutRegister.Container customHeight="560px">
        <LayoutRegister.Card
          backText="Criando um novo usuário para o Backoffice"
          subMenu={subMenu}
          onBack={() => history.push(routerBack)}
          confirmOnClick={false}
        >
          <Stack
            direction="column"
            padding="0.4375rem 0.9375rem 0 0.9375rem"
            flex="1"
            overflow="auto"
          >
            <Stack
              direction="column"
              padding="0.4375rem 0.9375rem 30px 0.9375rem"
              flex="1"
              overflow="auto"
              height="100%"
              justifyContent="space-between"
            >
              <div>
                <Stack gap="6px">
                  <IconSuccess
                    src={iconThemes[currentTheme.theme]?.IconDoneOutlined}
                    alt="icon-success"
                  />
                  <Title
                    label="Usuário cadastrado com sucesso."
                    color={currentTheme.colors.primary}
                  />
                </Stack>
                <Description>{descriptionSuccess}</Description>
              </div>

              <Flex alignItems="center" gap="0.75rem" justifyContent="flex-end">
                <ButtonRounded
                  id="botao_novo_cadastro"
                  color={currentTheme.colors.primary}
                  label="Cadastrar novo usuário"
                  iconSrc={iconThemes[currentTheme.theme]?.IconEnter}
                  click={() => {
                    setIsSuccessMessage(false);
                    createUserForm.reset();
                  }}
                  outline
                  border={`1px solid ${currentTheme.colors.primary}`}
                />

                <ButtonRounded
                  id="botao_finalizar"
                  background={currentTheme.colors.primary}
                  color={currentTheme.colors.white}
                  label="Finalizar"
                  iconSrc={iconThemes[currentTheme.theme]?.IconEnterHoverWhite}
                  click={() => {
                    setIsSuccessMessage(false);
                    navigateToMenu();
                  }}
                />
              </Flex>
            </Stack>
          </Stack>
        </LayoutRegister.Card>
      </LayoutRegister.Container>
    );
  }

  return isSuccessMessage ? SuccessMessage() : FormRegister();
};
