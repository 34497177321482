import ButtonBack from "components/atoms/ButtonBack";
import { useHistory } from "react-router-dom";


interface ILayoutBackProps {
  description?: string;
  backText?: string;
  onBack?: () => void;
  routerBack?: string;
}

export const LayoutBack = ({ description, backText, onBack, routerBack }: ILayoutBackProps) => {
  const history = useHistory();

  const handleBack = () => {
    if (onBack) return onBack();
    if (routerBack) return history.push(routerBack);
    history.goBack();
  }

  return (
    <ButtonBack
      description={description}
      text={backText}
      onBack={handleBack}
      padding="0"
    />
  );
}