import { yupResolver } from '@hookform/resolvers/yup';
import { validateLink } from 'helpers/functions';
import { isEmpty } from 'lodash';
import { IListsModel, IPopupImagemModel } from 'models';
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import * as yup from 'yup';

import { useLoading } from './loading';
import { useNotification } from './notification';
import { useConfirm } from './useConfirm';
import { PostPopupImage } from 'requests/PopupManagement';

export const validationSchemaCreateFormStep = yup
  .object({
    // name: yup.mixed().when('activeStep', {
    //   is: (activeStep: number) => activeStep === 1,
    //   then: yup.string().required('Informe o nome'),
    // }),
    popupFile: yup.mixed().when('activeStep', {
      is: (activeStep: number) => activeStep === 1,
      then: yup.mixed().required('informe o arquivo'),
    }),
    action: yup.mixed().when('activeStep', {
      is: (activeStep: number) => activeStep === 1,
      then: yup.number().required('Informe a ação'),
    }),
    startDate: yup.mixed().when(['activeStep', 'defineDate'], {
      is: (activeStep: number, defineDate: number) => activeStep === 3 && defineDate === 1,
      then: yup.mixed().required('Informe a data de início'),
    }),
    endDate: yup.mixed().when(['activeStep', 'defineDate'], {
      is: (activeStep: number, defineDate: number) => activeStep === 3 && defineDate === 1,
      then: yup.mixed().required('Informe a data de Final'),
    }),
    csvFile: yup.mixed().when(['activeStep', 'whoCanSee'], {
      is: (activeStep: number, whoCanSee: number) => activeStep === 4 && whoCanSee === 2,
      then: yup.mixed().required('Informe o arquivo'),
    }),
    whichBranches: yup.mixed().when(['activeStep'], {
      is: (activeStep: number) => activeStep === 4,
      then: yup.array().min(1).required('Informe a filial'),
    }),
    whichClassifications: yup.mixed().when(['activeStep'], {
      is: (activeStep: number) => activeStep === 4,
      then: yup.array().min(1).required('Informe a classificação'),
    }),
    segments: yup.mixed().when(['activeStep'], {
      is: (activeStep: number) => activeStep === 4,
      then: yup.array().min(1).required('Informe o banner'),
    }),
    planPoints: yup.mixed().when(['activeStep', 'planCondition'], {
      is: (activeStep: number, planCondition: number) => activeStep === 4 && planCondition,
      then: yup.string().required(''),
    }),
    activeStep: yup.number().required(''),
    link: yup.mixed(),
    internalLink: yup.mixed(),
    actionFile: yup.mixed(),
    actionLinkFile: yup.mixed(),
  })
  .test((values) => {
    return values.activeStep === 1
      ? values.action === 3 ||
          validateLink(values.link) ||
          values.internalLink ||
          values.actionFile ||
          validateLink(values.actionLinkFile)
      : true;
  });

interface IPopupImageContext {
  isEditing: boolean;
  created: boolean;
  setCreated: (val: boolean) => void;
  loading: boolean;
  list: IListsModel;
  setList: (list: IListsModel) => void;
  stepIsValid: (step: number) => boolean;
  activeStep: number;
  setActiveStep: (val: number) => void;
  steps: number[];
  setSteps: (val: number[]) => void;
  handleNext: () => Promise<void>;
  setLoading: (val: boolean) => void;
  handleCloseConfirm: (url: string) => void;
  createFormPopupImageStepper: ReturnType<typeof useForm<IPopupImageFormData>>;
  setIsEditing: (val: boolean) => void;
}

export interface IPopupImageFormData extends IPopupImagemModel {
  activeStep: number;
}

const PopupImageContext = createContext({} as IPopupImageContext);

export const CreatePopupImageProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const notify = useNotification();

  const history = useHistory();
  const { confirm } = useConfirm();

  const createFormPopupImageStepper = useForm<IPopupImageFormData>({
    defaultValues: {
      action: 3,
      popups: [{ name: '', value: 0 }],
      whichBranches: [{ value: 0, label: 'Todas' }],
      segments: [{ value: 0, label: 'Todos' }],
      whichClassifications: [{ value: 0, label: 'Todas' }],
      classificationPeriod: 1,
      defineDate: 1,
      endDateUndefined: false,
      whoCanSee: 1,
    },
    resolver: yupResolver(validationSchemaCreateFormStep),
  });

  const [isEditing, setIsEditing] = useState(false);
  const [created, setCreated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [steps, setSteps] = useState([1, 2, 3, 4, 5]);

  useEffect(() => {
    createFormPopupImageStepper.setValue('activeStep', activeStep, { shouldValidate: true });
  }, [activeStep]);

  const { handleHideLoading, handleShowLoading } = useLoading();

  const [list, setList] = useState({} as IListsModel);

  const stepIsValid = () => {
    return (
      createFormPopupImageStepper.formState.isValid &&
      isEmpty(createFormPopupImageStepper.formState.errors)
    );
  };

  const handleCreatePopup = async () => {
    handleShowLoading();
    setLoading(true);
    setCreated(false);
    await PostPopupImage(createFormPopupImageStepper.getValues())
      .then((_) => {
        setCreated(true);
      })
      .catch((err) => {
        setCreated(false);
        notify.error('Houve um problema', err.message, 5000);
        throw err;
      })
      .finally(() => {
        setLoading(false);
        handleHideLoading();
      });
  };

  const handleNext = async () => {
    if (isEditing) {
      if (stepIsValid()) {
        setIsEditing(false);
        setActiveStep(steps.length);
        return;
      }
    }
    // se já estiver no último step, redireciona para o menu
    if (activeStep < steps.length) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      return;
    }

    await handleCreatePopup();
  };

  const handleCloseConfirm = (url: string) => {
    confirm().then(() => {
      history.push(url);
    });
  };

  return (
    <PopupImageContext.Provider
      value={{
        isEditing,
        setIsEditing,
        created,
        setCreated,
        list,
        setList,
        loading,
        stepIsValid,
        activeStep,
        setActiveStep,
        steps,
        handleNext,
        setSteps,
        setLoading,
        handleCloseConfirm,
        createFormPopupImageStepper,
      }}
    >
      {children}
    </PopupImageContext.Provider>
  );
};

export const usePopupImage = () => {
  const context = useContext(PopupImageContext);

  return context;
};
