import { Layout } from 'components/templates/Layout';
import { subMenuChamas } from 'constants/subMenuChamas';
import useIconThemes from 'hooks/iconThemes';
import { useTheme } from 'hooks/useTheme';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { chamasAnalyticalURL } from 'routers/routes';

export const ChamasHome = () => {
  const { currentTheme } = useTheme();
  const iconThemes = useIconThemes();

  const history = useHistory();
  const navigate = (path: string) => {
    history.push(path);
  };

  useEffect(() => {
    document.title = 'Chamas | Backoffice Lojas';
  }, []);

  const optionsList = [
    {
      title: 'Analítico',
      subtitle: 'Manipule dados e baixe relatórios do Chamas',
      icon: iconThemes[currentTheme.theme].iconAnalitico,
      iconHover: iconThemes[currentTheme.theme].iconAnaliticoHover,
      onClick: () => navigate(chamasAnalyticalURL),
    },
  ];

  return (
    <Layout.Container>
      <Layout.Page width={currentTheme.sizes[537]} height={currentTheme.sizes[547]}>
        <Layout.Header subMenu={subMenuChamas} />
        <Layout.Body.Root>
          <Layout.Body.CardsOptions options={optionsList} columns={3} />
        </Layout.Body.Root>
      </Layout.Page>
    </Layout.Container>
  );
};
