import { ButtonEdit, Stack } from 'components/atoms';
import { IMenuItem } from 'components/atoms/Menu';
import StatusUser from 'components/atoms/StatusUser';
import { ListDefault } from 'components/molecules';
import { Layout } from 'components/templates/Layout';
import { generateKeyId, limitLenghtString } from 'helpers/functions';
import useIconThemes from 'hooks/iconThemes';
import { useTheme } from 'hooks/useTheme';
import { IBannerModel } from 'models';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GetAllBanners } from 'requests/BannerManagement';
import { EditBanner } from '../EditBanner';
import { Tag } from './styled';

const PAGE_LIMIT = 5;
const COLUMS_HEADER = ['Nº', 'Status', 'Nome do Banner', 'Vigência', 'Editar'];

interface IBannerManagementProps {
  subMenu: IMenuItem[];
}

export const BannerManagement = ({ subMenu }: IBannerManagementProps) => {
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [banners, setBanners] = useState<IBannerModel[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentBannerId, setCurrentBannerId] = useState<number | null>(null);
  const [currentDataBanner, setCurrentDataBanner] = useState<IBannerModel | null>(null);
  const [loading, setLoading] = useState(true);
  const iconThemes = useIconThemes();
  const { currentTheme } = useTheme();

  const getBannersData = async (_currentPage: number, _searchText?: string) => {
    setLoading(true);
    await GetAllBanners({ page: _currentPage, quantity: PAGE_LIMIT, name: _searchText ?? '' }).then(
      ({ data, total }) => {
        setTotalRecords(total);
        setBanners(data);
      }
    );
    setLoading(false);
  };

  useEffect(() => {
    document.title = 'Gerenciador de Banners | Turbo |  Backoffice Lojas';
  }, []);

  useEffect(() => {
    if (banners?.length && currentBannerId) {
      const data = banners?.filter((item) => item?.id === currentBannerId)[0];
      setCurrentDataBanner(data);
    }
  }, [currentBannerId, banners]);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      getBannersData(currentPage, search);
    }, 300);
    return () => clearTimeout(delayDebounce);
  }, [search, currentPage]);

  const handleClick = (id: number) => {
    setCurrentBannerId(id);
  };

  const history = useHistory();

  const rows = banners?.map((obj) => {
    return [
      obj?.viewOrder,
      <StatusUser type="Banner" status={obj?.status ?? ''} key={generateKeyId()} />,
      <Stack gap="6px">
        {limitLenghtString(obj?.name)}
        {(obj?.localBanner === 1 || obj?.localBanner === 0) && obj?.active !== false && (
          <Tag backgroundColor="#2E2B37">TURBO</Tag>
        )}
        {(obj?.localBanner === 2 || obj?.localBanner === 0) && obj?.active !== false && (
          <Tag backgroundColor="#f3123c">CHAMAS</Tag>
        )}
      </Stack>,
      obj?.validity,
      <ButtonEdit onClick={() => handleClick(obj.id)} key={generateKeyId()} />,
    ];
  });

  const handleCloseBanner = () => {
    setCurrentBannerId(null);
    setCurrentDataBanner(null);
  };

  return (
    <>
      <Layout.Container>
        <Layout.Page width="740px" height="547px">
          <Layout.Header subMenu={subMenu} />
          <Layout.Body.Root>
            <Layout.Body.Back onBack={() => history.replace('/turbo/banners')} />
            <ListDefault
              name="banner"
              pageLimit={PAGE_LIMIT}
              columnsHeader={COLUMS_HEADER}
              totalRecords={totalRecords}
              search={search}
              setSearch={setSearch}
              loading={loading}
              list={rows}
              setCurrentPage={setCurrentPage}
              img={iconThemes[currentTheme.theme]?.iconBanner}
            />
          </Layout.Body.Root>
        </Layout.Page>
      </Layout.Container>
      {currentBannerId && currentDataBanner ? (
        <EditBanner
          handleCLoseModal={handleCloseBanner}
          data={currentDataBanner}
          getBannersData={async () => await getBannersData(currentPage)}
        />
      ) : null}
    </>
  );
};
