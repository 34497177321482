import Title from 'components/atoms/Title';
import { useHistory } from 'react-router-dom';

import { WrapperTypes, CardWrapper } from './styled';
import { chamasHomeURL, turboHomeURL } from 'routers/routes';
import React from 'react';
import CardWithImage from 'components/atoms/CardImage';

export const PopupHome = ({ item }: { item: any }) => {
  const history = useHistory();

  const handleClick = (value: string) => {
    switch (value) {
      case 'button':
        history.push(chamasHomeURL);
        break;
      case 'image':
        history.push(turboHomeURL);
        break;
    }
  };

  return (
    <WrapperTypes>
      <p className="subtitle">Vamos começar!</p>
      <Title className="title" label="Escolha o sistema" />
      <CardWrapper>
        {item.map((data: any, index: string) => (
          <CardWithImage
            key={index}
            onClick={() => handleClick(data.value)}
            title={data.title}
            subtitle={data.subtitle}
            img={data.img}
            icon={data.icon}
            iconHover={data.iconHover}
            index={index}
            customHover
          />
        ))}
      </CardWrapper>
    </WrapperTypes>
  );
};
