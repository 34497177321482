import { Stack, ModalHeader, Input, ButtonRounded } from 'components/atoms';
import { validateEmail } from 'helpers/functions';
import { useLoading } from 'hooks/loading';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PostForgotPassword } from 'requests/Authentication';

import { Modal } from '../index';
import { LocalHeader, WrapSusses } from './styled';
interface IForgotPasswordProps {
  onClickExit: () => void;
}
export const ForgotPassword: React.FC<IForgotPasswordProps> = ({ onClickExit }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { handleShowLoading, handleHideLoading } = useLoading();

  const history = useHistory();

  const isEmailValid = (value: any) => {
    if (value?.length > 0) {
      if (!validateEmail(value)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const submitRequestPassword = async () => {
    setError(false);
    handleShowLoading();
    await PostForgotPassword(email, 2).then((response: any) => {
      if (response.data.data.HttpStatus === 200) {
        setError(false);
        setShowConfirmation(true);
      } else {
        setError(true);
      }
      handleHideLoading();
    });
  };

  return (
    <Stack width="100%" justifyContent="center">
      <Stack justifyContent="center" alignItems="center">
        {showConfirmation ? (
          <Modal
            height="150px"
            width="345px"
            background="#00D217"
            close={() => history.push('/login')}
          >
            <WrapSusses>
              <img src="/assets/icons/icon-check-white.svg" alt="check" />
              <h1>Enviamos para o seu e-mail as instruções de recuperação.</h1>
              <p>Utilize o link enviado para recuperar sua senha.</p>
            </WrapSusses>
          </Modal>
        ) : (
          <Modal
            iconTurbo
            height="302px"
            width="345px"
            background={error ? '#FF8332 ' : '#2F80ED'}
            close={onClickExit}
          >
            <ModalHeader display="flex" height="140px" background={error ? '#FF8332 ' : '#2F80ED'}>
              {error ? (
                <LocalHeader>
                  <img src="/assets/icons/icon-arrow-back.svg" alt="logo" />
                  <h1>Tente novamente.</h1>
                  <p>Verifique seus dados e tente novamente.</p>
                </LocalHeader>
              ) : (
                <LocalHeader>
                  <img src="/assets/icons/icon-password-outline.svg" alt="logo" />
                  <h1>Esqueceu a sua senha?</h1>
                  <p>Digite seu e-mail abaixo para iniciar o processo de recuperação de senha.</p>
                </LocalHeader>
              )}
            </ModalHeader>
            <Stack
              height="167px"
              background="#F1F1F1"
              padding="1.875rem"
              direction="column"
              borderBottomLeftRadius="0.5rem"
              borderBottomRightRadius="0.5rem"
            >
              <Input
                id="email"
                name="email"
                label="E-mail"
                placeholder="turbo@santander.com.br"
                iconSrc="/assets/icons/icon-email-outline.svg"
                type="text"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                error={isEmailValid(email) ? '' : 'E-mail incorreto'}
                isInvalid={!isEmailValid(email) || error}
              />
              <ButtonRounded
                click={() => {
                  submitRequestPassword();
                }}
                disabled={!isEmailValid(email)}
                label="Recuperar minha senha"
                iconSrc="/assets/icons/icon-arrow-rigth.svg"
                background="#2F80ED"
                color="#fff"
                margin="0.5rem auto"
              />
            </Stack>
          </Modal>
        )}
      </Stack>
    </Stack>
  );
};
