import { Layout } from 'components/templates/Layout';
import { subMenuTurbo } from 'constants/subMenuTurbo';
import useIconThemes from 'hooks/iconThemes';
import { useTheme } from 'hooks/useTheme';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { turboBannerCreateURL, turboBannerManagementURL, turboHomeURL } from 'routers/routes';

export const TurboBanners = () => {
  const iconThemes = useIconThemes();
  const history = useHistory();
  const { currentTheme } = useTheme();

  const navigate = (path: string) => {
    history.push(path);
  };

  useEffect(() => {
    document.title = 'Banners | Turbo | Backoffice Lojas';
  }, []);

  const optionsList = [
    {
      title: 'Criar um banner',
      subtitle: 'Faça o upload de um banner.',
      icon: iconThemes[currentTheme.theme]?.iconPlus || '',
      iconHover: iconThemes[currentTheme.theme]?.iconPlusHover || '',
      onClick: () => navigate(turboBannerCreateURL),
    },
    {
      title: 'Gerenciar banners',
      subtitle: 'Configure ou edite um banner.',
      icon: iconThemes[currentTheme.theme]?.iconGear || '',
      iconHover: iconThemes[currentTheme.theme]?.iconGearHover || '',
      onClick: () => navigate(turboBannerManagementURL),
    },
  ];

  return (
    <Layout.Container>
      <Layout.Page width={currentTheme.sizes[537]} height={currentTheme.sizes[547]}>
        <Layout.Header subMenu={subMenuTurbo} />
        <Layout.Body.Root>
          <Layout.Body.Back routerBack={turboHomeURL} />
          <Layout.Body.Title
            title="Banners"
            subtitle="Faça o upload de um novo banner ou gerencie banners publicados."
            iconTitle={iconThemes[currentTheme.theme]?.iconBanner}
          />
          <Layout.Body.CardsOptions options={optionsList} columns={1} />
        </Layout.Body.Root>
      </Layout.Page>
    </Layout.Container>
  );
};
