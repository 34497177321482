import { Layout } from 'components/templates/Layout';
import { subMenuTurbo } from 'constants/subMenuTurbo';
import useIconThemes from 'hooks/iconThemes';
import { useTheme } from 'hooks/useTheme';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  turboCarroselPictureDicionaryURL,
  turboCarrosselBackgroundURL,
  turboHomeURL,
} from 'routers/routes';

export const TurboCarrossel = () => {
  const history = useHistory();
  const { currentTheme } = useTheme();

  const iconThemes = useIconThemes();

  useEffect(() => {
    // define title page
    document.title = 'Carrossel | Turbo |  Backoffice Lojas';
  }, []);

  const navigate = (path: string) => {
    history.push(path);
  };

  const optionsList = [
    {
      title: 'Editar Plano de Fundo',
      subtitle: 'Gerencia o fundo do carrossel',
      icon: iconThemes[currentTheme.theme]?.iconBrush || '',
      iconHover: iconThemes[currentTheme.theme]?.iconBrushHover || '',
      onClick: () => navigate(turboCarrosselBackgroundURL),
    },
    {
      title: 'Dicionário de Imagens',
      subtitle: 'Crie ou gerencie as imagens de prêmios',
      icon: iconThemes[currentTheme.theme]?.iconTrophy || '',
      iconHover: iconThemes[currentTheme.theme]?.iconTrophyHover || '',
      onClick: () => navigate(turboCarroselPictureDicionaryURL),
    },
  ];

  return (
    <Layout.Container>
      <Layout.Page width={currentTheme.sizes[537]} height={currentTheme.sizes[547]}>
        <Layout.Header subMenu={subMenuTurbo} />
        <Layout.Body.Root>
          <Layout.Body.Back routerBack={turboHomeURL} />
          <Layout.Body.Title
            title="Carrossel de Prêmios"
            subtitle="Crie e gerencie os prêmios turbo e personalize o carrossel."
            iconTitle={iconThemes[currentTheme.theme]?.iconCarousel}
          />
          <Layout.Body.CardsOptions options={optionsList} columns={1} />
        </Layout.Body.Root>
      </Layout.Page>
    </Layout.Container>
  );
};
