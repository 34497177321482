import { Layout } from 'components/templates/Layout';
import useIconThemes from 'hooks/iconThemes';
import { useTheme } from 'hooks/useTheme';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  backofficeHomeURL,
  backofficeUsersCreateURL,
  backofficeUsersMangementURL,
} from 'routers/routes';

export const BackofficeUsers = () => {
  const iconThemes = useIconThemes();
  const history = useHistory();
  const { currentTheme } = useTheme();

  useEffect(() => {
    // define title page
    document.title = 'Usuários | Turbo | Backoffice Lojas';
  }, []);

  const navigate = (path: string) => {
    history.push(path);
  };

  const optionsList = [
    {
      title: 'Criar um usuário',
      subtitle: 'Crie um novo usuário do Backoffice.',
      icon: iconThemes[currentTheme.theme]?.iconPlus || '',
      iconHover: iconThemes[currentTheme.theme]?.iconPlusHover || '',
      onClick: () => navigate(backofficeUsersCreateURL),
    },
    {
      title: 'Gerenciar usuários',
      subtitle: 'Gerencie os usuários do Backoffice.',
      icon: iconThemes[currentTheme.theme]?.iconGear || '',
      iconHover: iconThemes[currentTheme.theme]?.iconGearHover || '',
      onClick: () => navigate(backofficeUsersMangementURL),
    },
  ];

  return (
    <Layout.Container>
      <Layout.Page width={currentTheme.sizes[537]} height={currentTheme.sizes[547]}>
        <Layout.Header />
        <Layout.Body.Root>
          <Layout.Body.Back routerBack={backofficeHomeURL} />
          <Layout.Body.Title
            title="Usuários Backoffice"
            subtitle="Crie ou gerencie usuários que tem acesso ao Backoffice."
            iconTitle={iconThemes[currentTheme.theme]?.iconCardUser || ''}
          />
          <Layout.Body.CardsOptions options={optionsList} columns={1} />
        </Layout.Body.Root>
      </Layout.Page>
    </Layout.Container>
  );
};
