import { Layout } from 'components/templates/Layout';
import { subMenuTurbo } from 'constants/subMenuTurbo';
import useIconThemes from 'hooks/iconThemes';
import { useTheme } from 'hooks/useTheme';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { turboHomeURL, turboPopupsCreateURL, turboPopupsManagementURL } from 'routers/routes';

export const TurboPopups = () => {
  const iconThemes = useIconThemes();
  const history = useHistory();
  const { currentTheme } = useTheme();

  const navigate = (path: string) => {
    history.push(path);
  };

  const optionsList = [
    {
      title: 'Criar um pop-up',
      subtitle: 'Acessar o construtor de pop-up.',
      icon: iconThemes[currentTheme.theme]?.iconPlus || '',
      iconHover: iconThemes[currentTheme.theme]?.iconPlusHover || '',
      onClick: () => navigate(turboPopupsCreateURL),
    },
    {
      title: 'Gerenciar pop-ups',
      subtitle: 'Configure um pop-up já publicado.',
      icon: iconThemes[currentTheme.theme]?.iconGear || '',
      iconHover: iconThemes[currentTheme.theme]?.iconGearHover || '',
      onClick: () => navigate(turboPopupsManagementURL),
    },
  ];

  useEffect(() => {
    document.title = 'Pop-ups | Turbo |  Backoffice Lojas';
  }, []);

  return (
    <Layout.Container>
      <Layout.Page width={currentTheme.sizes[537]} height={currentTheme.sizes[547]}>
        <Layout.Header subMenu={subMenuTurbo} />
        <Layout.Body.Root>
          <Layout.Body.Back routerBack={turboHomeURL} />
          <Layout.Body.Title
            title="Pop-ups"
            subtitle="Crie um novo pop-up ou gerencie pop-ups publicados."
            iconTitle={iconThemes[currentTheme.theme]?.iconPopup}
          />
          <Layout.Body.CardsOptions options={optionsList} columns={1} />
        </Layout.Body.Root>
      </Layout.Page>
    </Layout.Container>
  );
};
