import { Stack, StepCreated } from 'components/atoms';
import { LayoutCardContent, LayoutRegister } from 'components/templates';
import { MainLayoutCardContent } from 'globalStyles';
import { useCarouselBackground } from 'hooks/carouselBackground';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { device } from 'styles/breakpoint';

import { PreviewCarouselBackground } from '../PreviewCarouselBackground';
import { CreateCarouselBackgroundContent } from './CreateCarouselBackgroundContent';
import { IMenuItem } from 'components/atoms/Menu';

interface IStepperBannerProps {
  subMenu?: IMenuItem[];
  routerBack: string;
}

export const CreateCarouselBackground = ({
  subMenu,
  routerBack,
}: IStepperBannerProps): JSX.Element => {
  useEffect(() => {
    document.title = 'Criar Background | Turbo |  Backoffice Lojas';
  }, []);

  const history = useHistory();
  const { createBackgroundForm, handleClose, created } = useCarouselBackground();

  const [backgroundFile, name] = createBackgroundForm.watch(['backgroundFile', 'name']);

  return (
    <LayoutRegister.Container>
      {!created ? (
        <LayoutRegister.Card
          description="Criando um novo plano de fundo"
          onBack={() => handleClose(routerBack)}
          subMenu={subMenu}
        >
          <CreateCarouselBackgroundContent routerBack={routerBack} />
        </LayoutRegister.Card>
      ) : (
        <LayoutRegister.Card
          backText={'Voltar a tela de fundos'}
          onBack={() => history.replace(routerBack)}
          subMenu={subMenu}
          confirmOnClick={false}
        >
          <StepCreated
            title={name}
            name="Planos de Fundo"
            custonLabelbuttonAccess="Acessar Gerenciador de Planos de Fundo"
            route={`${routerBack}/gerenciador`}
            height="100%"
          >
            <Stack
              flex="1"
              justifyContent="center"
              alignItems="center"
              breakpointDownInvert={device.laptopM}
            >
              <PreviewCarouselBackground file={backgroundFile} />
            </Stack>
          </StepCreated>
        </LayoutRegister.Card>
      )}
      <Stack flex="1" justifyContent="center" alignItems="center" breakpointDown={device.laptopM}>
        <PreviewCarouselBackground file={backgroundFile} />
      </Stack>
    </LayoutRegister.Container>
  );
};
