import { Stack, Input } from 'components/atoms';
import { Modal, HeaderIconTitleText } from 'components/molecules';
import { PasswordValidation } from 'components/molecules/PasswordValidation';
import { ButtonEyes } from 'globalStyles';
import { validatePassowrd } from 'helpers/functions';
import { useAuthentiction } from 'hooks/authentication';
import { useNotification } from 'hooks/notification';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { PostExpiredPassword } from 'requests/Authentication';

import { ButtonSubmit, WrapperModal } from './styled';
import { useTheme } from 'hooks/useTheme';

export const PasswordExperied = () => {
  const notify = useNotification();
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordUsed, setPasswordUsed] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState(false);
  const [errorMensage, setErrorMensage] = useState({
    title: 'Acesso negado!',
    subtitle: 'Você não pode acessar esta página.',
  });
  const [sussesPopup, setSussesPopup] = useState(false);

  const history = useHistory();
  const { user, signout } = useAuthentiction();

  useEffect(() => {
    document.title = 'Senha | Backoffice Lojas';
  }, []);

  const disableButton = () => {
    const validate = validatePassowrd(password);

    if (
      validate.lower &&
      validate.upper &&
      validate.number &&
      validate.special &&
      validate.length
    ) {
      return false;
    }

    return true;
  };

  disableButton();

  const submitResetPassword = () => {
    PostExpiredPassword(user?.email ?? '', '3', password)
      .then((response) => {
        if (response.HttpStatus === 403) {
          setPasswordUsed(true);
          notify.error(
            'Senha inválida!',
            'Sua senha deve ser diferente das últimas 5 senhas utilizadas.'
          );
        } else if (response.HttpStatus === 200) {
          setSussesPopup(true);
        } else {
          setErrorMensage({
            title: 'Acesso negado!',
            subtitle: 'Você não pode acessar esta página.',
          });
          setError(true);
        }
      })
      .catch(() => {
        setError(true);
      });
  };

  const { currentTheme } = useTheme();

  return (
    <Stack width="100%" justifyContent="center">
      <Stack justifyContent="center" alignItems="center">
        <Modal // error modal
          height="116px"
          width="443px"
          background={currentTheme.colors.error}
          close={() => {
            history.push('/login');
            signout();
          }}
          display={error ? 'block' : 'none'}
        >
          <WrapperModal>
            <img src="/assets/icons/icon-close.svg" alt="check" />
            <h1>{errorMensage.title}</h1>
            <p>{errorMensage.subtitle}</p>
          </WrapperModal>
        </Modal>

        <Modal // sucess modal
          height="116px"
          width="345px"
          background={currentTheme.colors.success}
          close={() => {
            history.push('/login');
            signout();
          }}
          position={'absolut'}
          display={sussesPopup ? 'block' : 'none'}
        >
          <WrapperModal>
            <img src="/assets/icons/icon-check-circle.svg" alt="check" />
            <h1>Tudo certo!</h1>
            <p>Sua senha foi redefinida com sucesso!</p>
          </WrapperModal>
        </Modal>

        <Modal
          iconTurbo
          width="345px"
          background={currentTheme.colors.primary}
          display={!error && !sussesPopup ? 'block' : 'none'}
        >
          <Stack padding="1.875rem 1.875rem 0 1.875rem" gap="0.5rem" alignItems="center">
            <Stack cursor="pointer" onClick={signout}>
              <img src="/assets/icons/icon-rounded-back-white.svg" alt="back" />
            </Stack>
            <Stack
              cursor="pointer"
              textDecoration="underline"
              fontSize="10px"
              color="white"
              onClick={signout}
            >
              Voltar para a tela de login
            </Stack>
          </Stack>
          <HeaderIconTitleText
            srcIcon="/assets/icons/icon-password-white.svg"
            title="Sua senha expirou!"
            text="É necessário escolher uma nova senha para acessar o Backoffice do +Fidelidade Turbo."
            height="135px"
            background={currentTheme.colors.primary}
          />
          <Stack background={currentTheme.colors.white} padding="1.875rem" borderRadius="0px 0px 0.5rem 0.5rem">
            <Stack direction="column" flex="1">
              <Input
                id="senha"
                name="senha"
                label="Escolha sua nova senha"
                placeholder="*****"
                iconSrc="/assets/icons/icon-password-outline.svg"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                leadingIcon={() => (
                  <ButtonEyes type="button" onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? (
                      <img
                        src="/assets/icons/icon-eyes-open.svg"
                        alt="Ver senha"
                        title="Ver senha"
                      />
                    ) : (
                      <img
                        src="/assets/icons/icon-eyes-close.svg"
                        alt="Esconder senha"
                        title="Esconder senha"
                      />
                    )}
                  </ButtonEyes>
                )}
              />
              <Input
                id="repetir_senha"
                name="senha"
                label="Confirme sua nova senha"
                placeholder="*****"
                iconSrc="/assets/icons/icon-password-outline.svg"
                type={showConfirmPassword ? 'text' : 'password'}
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                leadingIcon={() => (
                  <ButtonEyes
                    type="button"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? (
                      <img
                        src="/assets/icons/icon-eyes-open.svg"
                        alt="Ver senha"
                        title="Ver senha"
                      />
                    ) : (
                      <img
                        src="/assets/icons/icon-eyes-close.svg"
                        alt="Esconder senha"
                        title="Esconder senha"
                      />
                    )}
                  </ButtonEyes>
                )}
              />

              <ButtonSubmit
                id="submit"
                onClick={() => submitResetPassword()}
                disabled={password !== confirmPassword || disableButton()}
                type="button"
              >
                <p>Redefinir minha senha</p>
                <img src="/assets/icons/icon-arrow-rigth.svg" alt="Submit" />
              </ButtonSubmit>
              <PasswordValidation value={password} passwordUsed={passwordUsed} />
            </Stack>
          </Stack>
        </Modal>
      </Stack>
    </Stack>
  );
};
