import { Stack, Input } from 'components/atoms';
import TextNotification from 'components/atoms/TextNotification/Index';
import { Modal, HeaderIconTitleText } from 'components/molecules';
import { PasswordValidation } from 'components/molecules/PasswordValidation';
import { ButtonEyes } from 'globalStyles';
import { validatePassowrd } from 'helpers/functions';
import { useLoading } from 'hooks/loading';
import { useNotification } from 'hooks/notification';
import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { GetValidRequest, PostResetPassword } from 'requests/Authentication';
import { ButtonSubmit } from './styled';
import { useTheme } from 'hooks/useTheme';

export const PasswordRegistration = () => {
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showModalPass, setShowModalPass] = useState(false);
  const [error, setError] = useState(false);
  const [errorExpirado, setErrorExpirado] = useState(false);
  const [typeRequest, setTypeRequest] = useState();
  const [passwordUsed, setPasswordUsed] = useState(false);

  const notify = useNotification();
  const { handleHideLoading, handleShowLoading } = useLoading();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    handleShowLoading();
    document.title = 'Senha | Backoffice Lojas';
    GetValidRequest(id)
      .then((response) => {
        if (response.HttpStatus === 200 && !response.Dado.expirado) {
          setTypeRequest(response.Dado.tipo_requisicao === 0 ? 1 : response.Dado.tipo_requisicao);
          setShowModalPass(true);
        } else {
          setErrorExpirado(response.Dado.expirado);
          setShowModalPass(false);
          setError(true);
        }
        handleHideLoading();
      })
      .catch(() => {
        setError(true);
        handleHideLoading();
      });
  }, [id]);

  useEffect(() => {
    if (error) {
      if (errorExpirado) {
        notify.error(
          'Este link não pode mais ser usado.',
          <TextNotification
            textLink=" Clique aqui"
            text=" para solicitar uma nova recuperação de senha."
            onClickLink={() => {
              notify.handleClearNotification();
              history.push('/senha-esquecida');
            }}
          />
        );
      } else {
        notify.error('Acesso negado!', 'Você não pode acessar esta página.');
      }
      history.push('/login');
    }
  }, [error, errorExpirado]);

  const disableButton = () => {
    const validate = validatePassowrd(password);

    if (
      validate.lower &&
      validate.upper &&
      validate.number &&
      validate.special &&
      validate.length
    ) {
      return false;
    }

    return true;
  };

  disableButton();

  const submitResetPassword = () => {
    handleShowLoading();
    PostResetPassword(id, typeRequest, password)
      .then((response) => {
        if (response.HttpStatus === 403) {
          setPasswordUsed(true);
          notify.error(
            'Senha inválida!',
            'Sua senha deve ser diferente das últimas 5 senhas utilizadas.'
          );
        } else if (response.HttpStatus === 200) {
          history.push('/login');
          notify.success(
            'Tudo certo!',
            typeRequest === 0 || typeRequest === 1
              ? 'Seu cadastro foi concluído com sucesso!'
              : 'Sua senha foi redefinida com sucesso!'
          );
        }
        handleHideLoading();
      })
      .catch(() => {
        setError(true);
        handleHideLoading();
      });
  };

  const { currentTheme } = useTheme();

  return (
    <Stack justifyContent="center" alignItems="center" flex="1">
      <Modal
        iconTurbo
        height="577px"
        width="345px"
        background={currentTheme.colors.primary}
        display={!error && showModalPass ? 'block' : 'none'}
      >
        <HeaderIconTitleText
          srcIcon={
            typeRequest === 0 || typeRequest === 1
              ? '/assets/icons/icon-backoffice-fidelidade-turbo.svg'
              : '/assets/icons/icon-password-white.svg'
          }
          title={typeRequest === 0 || typeRequest === 1 ? 'Boas-vindas!' : 'Redefinindo sua senha'}
          text={
            typeRequest === 0 || typeRequest === 1
              ? 'Chegou a hora de definir a sua senha para acessar o Backoffice do +Fidelidade Turbo!'
              : 'Escolha uma nova senha para acessar o Backoffice do +Fidelidade Turbo.'
          }
          height="135px"
          background={currentTheme.colors.primary}
        />
        <Stack
          height="442px"
          background={currentTheme.colors.white}
          padding="1.875rem"
          borderRadius="0 0 0.5rem 0.5rem"
        >
          <Stack direction="column" flex="1">
            <Input
              id="senha"
              name="senha"
              label="Escolha sua nova senha"
              placeholder="*****"
              iconSrc="/assets/icons/icon-password-outline.svg"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              leadingIcon={() => (
                <ButtonEyes type="button" onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? (
                    <img src="/assets/icons/icon-eyes-open.svg" alt="Ver senha" title="Ver senha" />
                  ) : (
                    <img
                      src="/assets/icons/icon-eyes-close.svg"
                      alt="Esconder senha"
                      title="Esconder senha"
                    />
                  )}
                </ButtonEyes>
              )}
            />

            <Input
              id="repetir_senha"
              name="senha"
              label="Confirme sua nova senha"
              placeholder="*****"
              iconSrc="/assets/icons/icon-password-outline.svg"
              type={showConfirmPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />

            <ButtonSubmit
              id="submit"
              onClick={() => submitResetPassword()}
              disabled={password !== confirmPassword || disableButton()}
              type="button"
            >
              <p>{typeRequest === 0 || typeRequest === 1 ? 'Definir' : 'Redefinir'} minha senha</p>
              <img src="/assets/icons/icon-arrow-rigth.svg" alt="Submit" />
            </ButtonSubmit>
            <PasswordValidation value={password} passwordUsed={passwordUsed} />
          </Stack>
        </Stack>
      </Modal>
    </Stack>
  );
};

